<template>
  <div>
     <span >
                  <van-popover
                      v-model="showPopover"
                      placement="bottom-end"
                      trigger="click"
                      :actions="actions"
                      @select="onSelect"
                  >
          <template #reference>
            <slot name="right">
<!--              <van-icon color="#006CDA" name="ellipsis" />-->
              <van-icon color="#006CDA" name="user-o"/>    <span
                style="color:#006CDA"></span>
            </slot>
          </template>
        </van-popover>
     </span>
    <van-dialog v-model="show" title="修改密码" :show-cancel-button=true confirmButtonColor="#006CDA" @confirm="handleOk">
      <van-field style="margin-bottom: 20px" v-model="name"  placeholder="请输修改的密码"/>
    </van-dialog>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "userNav",
  data() {
    return {
      showPopover: false,
       actions: [ {text: '退出账户'}],
      show: false,
      name: ''
    }
  },
  methods: {
    handleOk() {
      if (this.name == '') {
        this.$toast('请输入姓名')
        return
      }
      if (this.name.length < 2) {
        this.$toast('姓名长度不可小于2位')
        return
      }
      this.$store.dispatch("setUserName", this.name);
      this.show = false
    },
    onSelect(action) {
      if (action.text == "修改姓名") {
        this.show = true
      } else if(action.text == "退出账户") {
        //推出登陆，清楚各种信息
        Cookies.set('token', '')
        this.$router.push('/login')
      }
    },

  },
}
</script>

<style scoped>

</style>